import {
	BarChart2,
	Clipboard,
	Gamepad2Icon,
	GaugeCircleIcon,
	Grid,
	HomeIcon,
	NewspaperIcon,
	SlidersHorizontalIcon,
	UsersIcon,
} from 'lucide-react'

export const dashboardNavigation = [
	{ label: 'Overview', to: '/admin', icon: GaugeCircleIcon },

	{
		name: 'BLOG',
		items: [
			{
				label: 'Posts',
				to: '/admin/posts',
				icon: Gamepad2Icon,
				countKey: 'posts_total_count',
			},
			{
				label: 'Categories',
				to: '/admin/categories',
				icon: Grid,
				countKey: 'categories_total_count',
			},
		],
	},
	{
		name: 'USERS',
		items: [{ label: 'Users', to: '/admin/users', icon: UsersIcon }],
	},
	{
		name: 'SETTINGS',
		items: [
			{ label: 'SEO', to: '/admin/seo', icon: Clipboard },
			{ label: 'Analytics', to: '/admin/analytics', icon: BarChart2 },
			{
				label: 'Settings',
				to: '/admin/settings',
				icon: SlidersHorizontalIcon,
			},
		],
	},
]

export const HeaderNavigation = [
	{
		label: 'Home',
		to: '/',
		icon: HomeIcon,
	},

	{
		label: 'Users',
		to: '/users',
		icon: UsersIcon,
	},
]

export const AdminHeaderLinks = [
	{
		label: 'new post',
		to: '/posts/new',
	},

	{
		label: 'Admin',
		to: '/admin',
	},
	{
		label: 'Tests',
		to: '/tests',
	},
]
