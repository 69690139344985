// AdminSideItem.tsx
import { NavLink, useLocation } from '@remix-run/react'
import { ArrowLeft, ChevronDown, ChevronRight, Menu } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHydrated } from 'remix-utils/use-hydrated'
import { Button } from '../ui/button'
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '../ui/tooltip'
import { Badge } from '~/components/ui/badge'
import { cn } from '~/utils/misc'

type IconProps = {
	className?: string
}

type NavigationItem = {
	label: string
	to: string
	icon?: React.ComponentType<IconProps>
	countKey?: string
}

type NavigationElement =
	| NavigationItem
	| {
			name: string
			items: NavigationItem[]
	  }

type AdminSideItemProps = {
	navigation: NavigationElement[]
	counts: Record<string, number>
}

function isRecordStringBoolean(obj: any): obj is Record<string, boolean> {
	return (
		typeof obj === 'object' &&
		obj !== null &&
		!Array.isArray(obj) &&
		Object.values(obj).every((value) => typeof value === 'boolean')
	)
}

export function AdminSideItem({ navigation, counts }: AdminSideItemProps) {
	const location = useLocation()
	const isHydrated = useHydrated()
	const [openSections, setOpenSections] = useState<Record<string, boolean>>({})
	const [isCollapsed, setIsCollapsed] = useState(false)

	useEffect(() => {
		if (isHydrated) {
			const savedState = localStorage.getItem('adminSidebarState')
			const savedCollapsed = localStorage.getItem('adminSidebarCollapsed')
			if (savedState) {
				try {
					const parsedState = JSON.parse(savedState)
					if (isRecordStringBoolean(parsedState)) {
						setOpenSections(parsedState)
					}
				} catch (error) {
					console.error('Failed to parse admin sidebar state:', error)
				}
			} else {
				setOpenSections(
					navigation.reduce(
						(acc, item) => {
							if ('name' in item) {
								acc[item.name] = true
							}
							return acc
						},
						{} as Record<string, boolean>,
					),
				)
			}
			if (savedCollapsed) {
				const parsedCollapsed = JSON.parse(savedCollapsed)
				if (typeof parsedCollapsed === 'boolean') {
					setIsCollapsed(parsedCollapsed)
				}
			}
		}
	}, [isHydrated, navigation])

	useEffect(() => {
		if (isHydrated) {
			localStorage.setItem('adminSidebarState', JSON.stringify(openSections))
			localStorage.setItem('adminSidebarCollapsed', JSON.stringify(isCollapsed))
		}
	}, [openSections, isCollapsed, isHydrated])

	const toggleSection = useCallback((sectionName: string) => {
		setOpenSections((prevState) => ({
			...prevState,
			[sectionName]: !prevState[sectionName],
		}))
	}, [])

	const isActive = useCallback(
		(to: string) => {
			if (to === '/admin') {
				return location.pathname === '/admin'
			} else {
				return location.pathname.startsWith(to) && to !== '/admin'
			}
		},
		[location.pathname],
	)

	const renderNavigationItem = (item: NavigationItem) => (
		<Tooltip>
			<TooltipTrigger asChild>
				<NavLink
					to={item.to}
					className={cn(
						'flex items-center gap-4 rounded-md px-4 py-2 text-foreground duration-150 ease-in-out hover:bg-muted hover:text-foreground',
						isActive(item.to) && 'text-secondcolor',
						isCollapsed && 'justify-center',
					)}
				>
					{item.icon ? (
						<item.icon className="h-5 w-5" />
					) : (
						<span className="flex h-5 w-5 items-center justify-center">#</span>
					)}
					{!isCollapsed && (
						<>
							<span className="flex-1">{item.label}</span>
							{item.countKey && counts[item.countKey] !== undefined && (
								<Badge className="ml-auto">
									{counts[item.countKey].toString()}
								</Badge>
							)}
						</>
					)}
				</NavLink>
			</TooltipTrigger>
			{isCollapsed && (
				<TooltipContent side="right" align="center">
					{item.label}
				</TooltipContent>
			)}
		</Tooltip>
	)

	if (!navigation) return null

	return (
		<TooltipProvider>
			<nav
				className={cn(
					'relative border-r bg-background p-4',
					isCollapsed ? 'w-18' : 'w-64',
				)}
			>
				<div className="space-y-4">
					{navigation.map((item, index) => (
						<div key={index}>
							{'name' in item ? (
								<>
									{!isCollapsed && (
										<Button
											variant="ghost"
											className="flex w-full items-center justify-between"
											onClick={() => toggleSection(item.name)}
										>
											<span className="font-medium">{item.name}</span>
											{openSections[item.name] ? (
												<ChevronDown className="h-4 w-4" />
											) : (
												<ChevronRight className="h-4 w-4" />
											)}
										</Button>
									)}
									{!isCollapsed && openSections[item.name] && (
										<ul className="mt-2 space-y-2 pl-4">
											{item.items.map((subItem) => (
												<li key={subItem.label}>
													{renderNavigationItem(subItem)}
												</li>
											))}
										</ul>
									)}
									{isCollapsed && (
										<ul>
											{item.items.map((subItem) => (
												<li key={subItem.label}>
													{renderNavigationItem(subItem)}
												</li>
											))}
										</ul>
									)}
								</>
							) : (
								renderNavigationItem(item)
							)}
						</div>
					))}
				</div>
				<Button
					variant="ghost"
					className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 transform rounded-full border-l border-gray-200 bg-background p-2"
					onClick={() => setIsCollapsed(!isCollapsed)}
				>
					{isCollapsed ? (
						<ArrowLeft size={20} className="rotate-180" />
					) : (
						<ArrowLeft size={20} />
					)}
				</Button>
			</nav>
		</TooltipProvider>
	)
}

export function AdminSideItemsheet({ navigation, counts }: AdminSideItemProps) {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Sheet open={isOpen} onOpenChange={setIsOpen}>
			<SheetTrigger asChild>
				<Button
					variant="link"
					size="icon"
					className="shrink-0 md:hidden"
					onClick={() => setIsOpen(true)}
				>
					<Menu className="h-5 w-5" />
					<span className="sr-only">Toggle navigation menu</span>
				</Button>
			</SheetTrigger>
			<SheetContent side="left" className="flex flex-col">
				<AdminSideItem navigation={navigation} counts={counts} />
			</SheetContent>
		</Sheet>
	)
}
